.comment-view-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comment-view-title{
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
    align-self: center;
}

.comment-view-button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 10px;
    gap: 20px;
}

.comment-view-button{
    width: 53px;
    height: 53px;
    box-shadow: 0px 0px 6px #85858580;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.comment-view-button-edit{
    background-color: #F1C50B;
}

.comment-view-button-delete{
    background-color: #FF5253;
}

.comment-view-txt{
    font-size: 14px;
    margin: 0;
    /** Affiche les sauts de lignes */
    white-space: pre-line;
}

.comment-view-no-comment{
    font-style: italic;
    opacity: 0.6;
}