.login-main{
    position: absolute;
    width: 100vw;
    display: grid;
    grid-template-columns: [col1] 10% [col2] auto [col3] 10%;
    grid-template-rows: [row1] 20% [row2] 10% [row3] auto [row4] 150px;
    justify-content: stretch;
}

.login-title{
    grid-row: 1;
    grid-column: 2;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
    object-fit: contain;
}
.login-description{
    text-align: center;
    grid-row: 2;
    grid-column: 2;
}
.login-img{
    flex-grow: 1;
    align-self: center;
    justify-self: center;
    grid-row: 3;
    grid-column: 2;
}

.login-bottom-button{
    grid-row: 4;
    grid-column: 2;
}
