.pin-main{
    position: absolute;
    overflow: hidden;
    width: 100%;
    display: grid;
    grid-template-columns: [col1] 10% [col2] auto [col3] 10%;
    grid-template-rows: [row1] 15% [row2] 10% [row3] auto [row4] 80px [row5] 120px;
    justify-content: stretch;
}

.pin-title{
    font-size: 50px;
    text-align: left;
    grid-row: 1;
    grid-column: 2;
    align-self: end;
    margin: 0;
}

.pin-subtitle{
    font-size: 30px;
    text-align: left;
    grid-row: 2;
    grid-column: 2;
    margin: 0;
}

.pin-container{
    overflow: hidden;
    grid-row: 3;
    grid-column: 2;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
}

/* CODE PIN */

.pin-text{
    text-align: center;
    font-size: 16px;
}

.pin-separator{
    height: 1px;
    align-self: stretch;
    border: 0;
    border-radius: 10px;
    background-color: #707070;
}

.pin-input-container{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    height: 60px;
}

.pin-input{
    background: #FFFFFF;
    border-radius: 20px;
    width: 22%;
    border: 0;
    font-size: 20px;
    text-align: center;
    box-shadow: inset 0px 3px 6px #00000029;
}

/* CODE PIN */

.pin-validate-button{
    grid-row: 4;
    grid-column: 2;
}

.pin-cancel-button{
    grid-row: 5;
    grid-column: 2;
}