.rolestate-main{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    height: 69px;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 17px;
}

.rolestate-icon-container{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
}

.rolestate-icon-container img{
    width: 25px;
    height: 25px;
}

.rolestate-main p{
    margin: 0;
    margin-left: 15px;
    font-size: 20px;
}