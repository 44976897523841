.equi-main{
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
}


.equi-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
}

.equi-title{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.equi-detail{
    font-size: 16px;
    margin: 0;
    text-decoration: underline;
}

.equi-description{
    font-size: 16px;
    margin: 0;
}

.equi-description::before{
    content: '- ';
    
}