@keyframes bottomToUp {
    from{
        margin-top: 100vw;
        height: 300vw;
    }

    to{
        margin-top: 0vw;
        height: 100vw;
    }
}

@keyframes UpToBottom {
    to{
        margin-top: 120vw;
        height: 300vw;
    }

    from{
        margin-top: 0vw;
        height: 100vw;
    }
}

@keyframes fadeIn {
    from{
        background-color: rgba(0, 0, 0, 0);
    }

    to{
        background-color: rgba(0, 0, 0, 0.2);
    }
}

@keyframes fadeOut {
    to{
        background-color: rgba(0, 0, 0, 0);
    }

    from{
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.select-role-main{
    width: 100dvw;
    height: 100dvh;
    position: absolute;
    overflow: hidden auto;
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
    animation-duration: 450ms;
    animation-name: fadeIn;
    opacity: 1;
    scroll-behavior: smooth;
    pointer-events:fill;
}

.select-role-empty-space{
    height: 50vh;
}

.select-role-wrapper{
    width: 100dvw;
    min-height: 50dvh;
    border-radius: 20px 20px 0px 0px;
    background-color: white;
    box-shadow: 0px -3px 6px #00000029;
    animation-duration: 450ms;
    animation-name: bottomToUp;
}

.select-role-container{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
}

.select-role-round-square{
    background-color: #707070;
    opacity: 0.2;
    width: 51px;
    height: 5px;
    align-self: center;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 50px;
}

.select-role-txt{
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
    align-self: center;
    text-align: center;
}

.select-role-element{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: var(--background-primary);
    border-radius: 10px;
    height: 90px;
}

.select-role-element p{
    margin: 0;
    margin-right: 20px;
    font-size: 18px;
    font-weight: bold;
}

.select-role-element img{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
}