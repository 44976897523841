.editEquipments-main{
    display: flex;
    flex-direction: column;
    width: 100dvw;
    height: 100dvh;
    animation: var(--animation-slide-in);
    background-color: var(--background-primary);
}

.editEquipments-wrapper{
    display: block;
    overflow: hidden auto;
    height: calc(100dvh - var(--nav-height));
}

.editEquipments-container{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.editEquipments-header{
    margin: 0;
    margin-top: 12px;
    font-weight: normal;
    text-align: left;
    font-size: 40px;
    color: black;
}

.editEquipments-subheader{
    margin: 0;
    margin-top: 12px;
    font-weight: normal;
    text-align: left;
    font-size: 14px;
    color: black;
    font-style: italic;
}

.editEquipments-title{
    margin: 0;
    margin-top: 12px;
    font-weight: normal;
    text-align: left;
    font-size: 25px;
    color: black;
}

.editEquipments-separator{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 20px;
    height: 1px;
    align-self: stretch;
    border: 0;
    border-radius: 10px;
    background-color: #707070;
    opacity: 0.2;
}

.editEquipments-equip-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    height: 45px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #91919180;
}

.editEquipments-equip-container p{
    margin-left: 1em;
}

.editEquipments-equip-button{
    border-radius: 10px;
    width: 40px;
    height: 40px;
    box-shadow: 0px 3px 6px #91919180;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.editEquipments-equip-button img{
    width: 25px;
    height: 25px;
}

.editEquipments-add-equip-button{
    border-radius: 10px;
    height: 50px;
    box-shadow: 0px 3px 6px #91919180;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3DBFF1;
    margin-top: 20px;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.editEquipments-add-equip-button img{
    margin-top: 3px;
    margin-right: 10px;
}

.editEquipments-add-equip-button p{
    margin: 0;
    color: white;
    font-size: 20px;
}