:root{
    --consultTable-height-header: 66px;
    --consultTable-height-content: 69px;
    --consultTable-margin-bottom-element: 16px;
    --consultTable-shadow: 0px 3px 6px #00000029;
}

.consultTable-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.consultTable-header{
    margin-bottom: var(--consultTable-margin-bottom-element);
    height: var(--consultTable-height-header);
    /*background: linear-gradient(180deg, #CBC9C9 0%, #9F9F9F 100%);*/
    border-radius: 10px;
    box-shadow: var(--consultTable-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
}

.consultTable-table-container{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: var(--consultTable-margin-bottom-element);
    overflow: hidden;
    box-shadow: var(--consultTable-shadow);
}

.consultTable-table-header{
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: linear-gradient(180deg, #3DBFF1 0%, #38ACD9 100%);*/
    height: var(--consultTable-height-header);
}

.consultTable-title{
    color: white;
    text-shadow: var(--consultTable-shadow);
    font-size: 25px;
    margin: 0;
    font-weight: normal;
    text-wrap: wrap;
    text-align: center;
}

.consultTable-table-element{
    display: grid;
    height: var(--consultTable-height-content);
    grid-template-columns: 23% 77%;
    grid-template-rows: 45% 55%;
}

.consultTable-table-icon{
    grid-column: 1;
    grid-row: 1 / span 2;
    justify-self: right;
    align-self: center;
    object-fit: cover;
    height: calc(var(--consultTable-height-content) * 0.55);
}

.consultTable-table-element-header{
    grid-column: 2;
    grid-row: 1;
    margin: 0;
    align-self: flex-end;
    justify-self: center;
    font-size: 15px;
    color: #707070;
}

.consultTable-table-element-value{
    grid-column: 2;
    grid-row: 2;
    margin: 0;
    align-self: flex-start;
    justify-self: center;
    font-size: 25px;
    font-weight: 300;
    color: #707070;
}