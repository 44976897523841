.myselect-main{
    display: flex;
    flex-direction: column;
}

.myselect-select{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    height: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.myselect-select p{
    font-size: 14px;
    margin-left: 8px;
    text-wrap: nowrap;
}

.myselect-no-selected{
    font-style: italic;
    opacity: 0.5;
}

.myselect-select img{
    margin: 14px;
    margin-right: 8px;
}

.myselect-options-container{
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    z-index: 1;
    flex-direction: column;
    overflow-y: auto;
}

.myselect-options{
    height: 43px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.myselect-options p{
    font-size: 14px;
    margin-left: 8px;
    text-wrap: nowrap;
}