.home-main{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 7%;
    margin-right: 7%;
    animation-duration: 0.5s;
    animation-name: slide-in;

    flex-wrap: nowrap;
}

.home-user-section{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;

    flex-wrap: nowrap;
}

.home-user-detail{
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-user-name{
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.home-user-info{
    text-align: right;
    font-size: 18px;
    margin: 0;
}

.home-user-icon-container{
    min-width: 61px;
    height: 69px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 40px;
    gap: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    
    justify-content: center;
    align-items: center;
}

.home-user-icon{
    width: 30px;
    height: 30px;
}

.home-separator{
    height: 1px;
    align-self: stretch;
    border: 0;
    border-radius: 10px;
    background-color: #707070;
    margin-bottom: 40px;
}

.home-button{
    height: 165px;
    overflow: hidden;
    background-color: white;
    align-self: stretch;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: [col1] 180px [col2] 1fr;
    grid-template-rows: [row1] 25% [row2] 25% [row3] 25% [row3] 25%;
    padding: 0;
    border: 0;
}

.home-button-img{
    grid-column: 1;
    grid-row: 1 / span 4;
    height: 100%;
}

.home-button-fade{
    background: transparent linear-gradient(90deg, #FFFFFF00 0px,#FFFFFF 190px,#FFFFFF 100%) 0% 0% no-repeat padding-box;
    grid-column: 1 / span 3;
    grid-row: 1 / span 4;
    border-radius: 20px;
    margin: 0;
    align-self: stretch;
    justify-self: stretch;
}

.home-button-text{
    font-size: 20px;
    grid-column: 2;
    grid-row: 2;
    justify-content: end;
    align-self:end;
    text-align: right;
    margin: 0;
    margin-right: 10px;
}

.home-button-info{
    font-size: 10px;
    grid-column: 2;
    grid-row: 3;
    justify-content: start;
    align-self:stretch;
    text-align: right;
    margin: 0;
    margin-right: 10px;
}

.home-parameter-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}