.search-bar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-shadow: 0px 0px 6px #00000029;
    border: 2px solid #707070;
    border-radius: 40px;
    opacity: 1;
    height: 55px;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;
}

.search-bar input{
    border: 0;
    margin: 0;
    width: calc(100% - 30px);
    background-color: transparent;
    font-size: 20px;
    color: #707070;
    outline: none;
}

.search-bar input::placeholder{
    color: #7070706b;
}

.search-button{
    width: 30px;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 15px;
    animation-duration: 450ms;
}

@keyframes showButton{
    0%{
        transform: translateX(50px);
    }
    100%{
        transform: translateX(0px);
    }
}

@keyframes hideButton{
    0%{
        transform: translateX(0px);
    }
    100%{
        transform: translateX(50px);
    }
    
}