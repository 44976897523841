.tmpLogin-main{
    background-color: var(--background-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100dvw;
    height: 100dvh;
}

.tmpLogin-main h1{
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    margin-bottom: 20px;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 99px;
}

.tmpLogin-txt-container{
    flex-grow: 1;
}

.tmpLogin-txt-container p{
    margin: 0;
    font-size: 18px;
    margin-left: 35px;
    margin-right: 35px;
    justify-self: flex-start;
}

.tmpLogin-main button{
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 20px;
}

.tmpLogin-main button p{
    font-size: 20px;
}

.tmpLogin-cancel-button{
    background: #FF5253;
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 50px;
    color: white;
}