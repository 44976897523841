.consultSign-main{
    display: flex;
    flex-direction: column;
    animation: var(--animation-slide-in);
    background-color: var(--background-primary);
}

.consultSign-wrapper{
    display: block;
    overflow: hidden auto;
    height: calc(100dvh - var(--nav-height) * 2);
}

.consultSign-container{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.consultSign-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: var(--nav-height);
    background-color: white;
    box-shadow: 0px -3px 6px #00000029;
    z-index: 1;
}

.consultSign-bottom img{
    width: 27px;
    height: 27px;
    opacity: 1;
}