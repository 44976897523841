.measure-main{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.measure-header{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-top: 20px;
} 

.measure-no-data{
    font-size: 18px;
    margin: 0;
    margin-top: 20px;
    font-style: italic;
    align-self: center;
}

.measure-item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    align-items: center;
}

.measure-type{
    text-decoration: underline;
    font-size: 18px;
    margin: 0;
}

.measure-value{
    font-size: 18px;
    margin: 0;
}

.measure-unit{
    font-size: 18px;
    margin: 0;
    margin-left: 6px;
}

.measure-button{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 178px;
    height: 53px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-self: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.measure-button-edit{
    background: #EACA3E 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #EACA3E80;
}

.measure-button-add{
    background: #3DBFF1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #85858580;
}

.measure-button p{
    margin: 0;
    color: white;
    font-size: 20px;
}

.measure-button img{
    margin: 13px 20px;
}