.equidetail-main{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: var(--background-primary);
    animation-duration: 0.5s;
    animation-name: slidein;
    height: 100%;
    width: 100%;
    overflow: none;
}

.goback-main{
    flex-basis: var(--nav-height);
}

.equidetail-header{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
}

.equidetail-title{
    margin: 0;
    margin-top: 20px;
    font-weight: normal;
    text-align: left;
    font-size: 40px;
    color: black;
}

.equidetail-wonumber{
    font-size: 18px;
    margin: 0;
    margin-top: 20px;
}

.equidetail-content{
    flex-grow: 1;
    height: 50dvh;
    overflow: hidden;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 36px 1fr 1fr 1fr 1fr 1fr;
}

.equidetail-img{
    height: 100%;
    width: 100%;
    object-fit:cover;
    object-position: 100% 0;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 10;
}

.equidetail-epi-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.equidetail-epi-container p{
    font-size: 16px;
    text-align: left;
    margin: 0;
    margin-left: 10px;
}

.equidetail-head{
    grid-column-start: 2;
    grid-row-start: 2;
}

.equidetail-body{
    grid-column-start: 2;
    grid-row-start: 3;
}

.equidetail-hands{
    grid-column-start: 2;
    grid-row-start: 4;
}

.equidetail-legs{
    grid-column-start: 2;
    grid-row-start: 5;
}

.equidetail-feet{
    grid-column-start: 2;
    grid-row-start: 6;
}