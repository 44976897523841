.signstate-main{
    width: 100vw;
}

.signstate-container{
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-left: 30px;
    align-items: center;
    justify-content: center;
}

.signstate-qrcode-container{
    margin-top: 33px;
    width: 193px;
    height: 193px;
}

.signstate-workorder-num{
    margin: 0;
    margin-top: 20px;
    font-size: 20px;
}

.signstate-separator{
    margin: 0;
    margin-top: 20px;
    height: 1px;
    align-self: stretch;
    border: 0;
    border-radius: 10px;
    background-color: #707070;
    opacity: 0.2;
}

.signstate-txt{
    margin: 0;
    font-size: 20px;
    color: #707070;
}

.signstate-cancel{
    align-self: stretch;
    margin-top: 20px;
    background: #FF5253;
    box-shadow: 0px 3px 6px #00000029;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 20px;
}

.signstate-bottom-container{
    bottom: 0;  
    position: absolute;
    margin: 30px;
}