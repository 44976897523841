.equipmentsEdit-main{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.equipmentsEdit-header{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-top: 20px;
} 

.equipmentsEdit-button{
    margin-top: 20px;
    margin-bottom: 20px;
    height: 53px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-self: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    gap: 1em;
    padding-right: 1em;
    padding-left: 1em;
    background: #EACA3E 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #EACA3E80;
}

.equipmentsEdit-button p{
    margin: 0;
    color: white;
    font-size: 20px;
}

.equipmentsEdit-badges-container{
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 20px;
    justify-content: flex-start;
    align-items: stretch;
    gap: 3px;
}

.equipmentsEdit-badges-container div{
    color: white;
    font-size: 10px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 100%;
    text-align: center;
    min-width: 15px;
}