.measure-editor-main{
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    animation: var(--animation-slide-in);
    background-color: var(--background-primary);
}

.measure-editor-wrapper{
    display: block;
    overflow: hidden auto;
    height: calc(100dvh - var(--nav-height));
}

.measure-editor-container{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
    justify-content: stretch;
    align-items: stretch;
}

.measure-editor-header{
    margin: 0;
    margin-top: 12px;
    font-weight: normal;
    text-align: left;
    font-size: 40px;
    color: black;
}

.measure-editor-subheader{
    margin: 0;
    margin-top: 12px;
    font-weight: normal;
    text-align: left;
    font-size: 14px;
    color: black;
    font-style: italic;
}

.measure-editor-wo-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.measure-editor-wo-title{
    margin: 0;
    margin-top: 12px;
    font-weight: normal;
    text-align: left;
    font-size: 25px;
    color: black;
}

.measure-editor-separator{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 20px;
    height: 1px;
    align-self: stretch;
    border: 0;
    border-radius: 10px;
    background-color: #707070;
    opacity: 0.2;
}

.measure-editor-item-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    align-items: center;
}

.measure-editor-item-container img{
    width: 20px;
}

.measure-editor-item-container input{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border: 0;
    border-radius: 10px;
    height: 43px;
    text-align: center;
    font: normal normal normal 14px/19px Segoe UI;
    width: 30%;
}

.measure-editor-item-container input::placeholder{
    font-style: italic;
}

.measure-editor-button-add{
    margin-top: 20px;
    width: 178px;
    height: 53px;
    background-color: #3DBFF1;
    box-shadow: 0px 0px 6px #85858580;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
}

.measure-editor-button-add p{
    margin: 0;
    color: white;
    font-size: 20px;
}

.measure-editor-button-add img{
    margin: 13px 20px;
}

.measure-save-button{
    background-color: #3DBFF1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 40px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 6px #85858580;
}

