.parameter-main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    animation-duration: 0.5s;
    animation-name: slidein;
    background-color: var(--background-primary);
}

.parameter-element{
    height: 73px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.parameter-element-icon{
    margin-left: 20px;
    height: 40px;
    width: 40px;
}

.parameter-element-text{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}