.qrcode-main{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.qrcode-desc{
    flex-grow: 1;
}

.qrcode-container{
    flex-grow: 8;
    background-color: white;
    width: 100vw;
    align-items: stretch;
}

.qrcode-button{
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
}