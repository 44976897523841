.infodetails-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    height: unset;
}

.infodetails-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 23px;
    height: 70px;
    cursor: pointer;
}

.infodetails-title{
    margin: 0px;
    font-weight: normal;
    text-align: center;
    font-size: 20px;
    color: black;
    align-self: center;
    margin-left: 9px;
    margin-bottom: 3px;
}

.infodetails-info-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 10px;
    margin: 0px 23px;
}

.infodetails-info{
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    color: black;
    margin-bottom: 10px;
}

.infodetails-info::before{
    content: '-';
    margin-right: 10px;
}