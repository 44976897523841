.pdfReader-main{
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    animation-duration: 0.5s;
    animation-name: slidein;
    background-color: var(--background-primary);
}

.pdfReader-container{
    overflow: auto;
    /*width: 100dvw;*/
    height: calc(100dvh - var(--nav-height) - var(--nav-height));
    display: block;
    z-index: 0;
}


.pdfReader-bottom{
    height: var(--nav-height);
    background-color: white;
    box-shadow: 0px -3px 6px #00000029;
    display: flex;
    z-index: 2;
    width: 100dvw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.pdfReader-bottom img{
    width: 27px;
    height: 27px;
    opacity: 1;
}
