.nb-worker-view-main{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.nb-worker-view-title{
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
    align-self: center;
}

.nb-worker-view-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 2em;
    margin-right: 2em;
}

.nb-worker-view-button-edit{
    width: 53px;
    height: 53px;
    background-color: #F1C50B;
    box-shadow: 0px 0px 6px #85858580;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.nb-worker-view-txt{
    font-size: 18px;
    margin: 0;
}

.nb-worker-view-nb-worker{
    font-size: 20px;
    font-weight: bold;
    margin-left: 8px;
}

.nb-worker-view-no-worker{
    font-style: italic;
    opacity: 0.6;
}