.worders-main{
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.wordres-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    filter: blur(30px);
    -webkit-filter: blur(30px);
    background-image: url("./../images/illustrations/work-order.png");
    z-index: -10;
    background-position:center;
    background-repeat: no-repeat;
    background-size: auto 120%;
}

.worders-wrapper{
    background-color: transparent;
    display: block;
    overflow: hidden auto;
    height: calc(100dvh - var(--nav-height));
}

.worders-container{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    flex-wrap: nowrap;
}

.worders-type-search{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 4px;
}

.worders-section{
    margin: 0;
    margin-top: 20px;
    font-weight: normal;
    text-align: left;
    font-size: 40px;
    color: black;
}

.worders-txt{
    margin: 0;
    margin-top: 20px;
    font-weight: normal;
    text-align: center;
    font-size: 20px;
    color: black;
}

.worders-separator{
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 1px;
    align-self: stretch;
    border: 0;
    border-radius: 10px;
    background-color: #707070;
}

.worders-more-button{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.worders-more-button p{
    margin: 0;
    font-size: 20px;
}