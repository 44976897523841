.formDisplay-main{
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    animation-duration: 0.5s;
    animation-name: slidein;
    background-color: var(--background-primary);
}

.formDisplay-container{
    overflow: auto;
    /*width: 100dvw;*/
    height: calc(100dvh - var(--nav-height) *2.2);
    border: 0;
    display: block;
    z-index: 0;
}

.formDisplay-footer{
    height: calc(var(--nav-height) *1.2);
    background-color: white;
    box-shadow: 0px -3px 6px #00000029;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    align-self: stretch;
    z-index: 1;
}

.formDisplay-footer div{
    margin: 0;
    align-self: center;
}