@keyframes bottomToUp {
    from{
        margin-top: 100vw;
        height: 300vw;
    }

    to{
        margin-top: 0vw;
        height: 100vw;
    }
}

@keyframes UpToBottom {
    to{
        margin-top: 120vw;
        height: 300vw;
    }

    from{
        margin-top: 0vw;
        height: 100vw;
    }
}

@keyframes fadeIn {
    from{
        background-color: rgba(0, 0, 0, 0);
    }

    to{
        background-color: rgba(0, 0, 0, 0.2);
    }
}

@keyframes fadeOut {
    to{
        background-color: rgba(0, 0, 0, 0);
    }

    from{
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.declsign-main{
    width: 100vw;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
    overflow: hidden auto;
    animation-duration: 450ms;
    animation-name: fadeIn;
    opacity: 1;
    scroll-behavior: smooth;
}

.declsign-empty-space{
    height: 50vh;
}

.declsign-wrapper{
    width: 100vw;
    min-height: 50vh;
    border-radius: 20px 20px 0px 0px;
    background-color: white;
    box-shadow: 0px -3px 6px #00000029;
    animation-duration: 450ms;
    animation-name: bottomToUp;
}

.declsign-container{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
}

.declsign-round-square{
    background-color: #707070;
    opacity: 0.2;
    width: 51px;
    height: 5px;
    align-self: center;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 50px;
}

.declsign-txt{
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
    align-self: center;
}

.declsign-info{
    font-size: 16px;
    font-style: italic;
    opacity: 0.6;
    margin: 0;
    margin-bottom: 20px;
    align-self: center;
}

.declsign-separator{
    margin: 0;
    margin-bottom: 20px;
    height: 1px;
    align-self: stretch;
    border: 0;
    border-radius: 10px;
    background-color: #707070;
    opacity: 0.2;
}

.declsign-checkbox-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 5px;
    margin-bottom: 20px;
    align-items: center;
}

.declsign-checkbox-container input{
    width: 24px;
    height: 24px;
    border: 2px solid #707070;
}

.declsign-checkbox-container label{
    font-size: 18px;
    margin-left: 12px;
}

.declsign-container-buttons{
    height: 295px;
    align-self: stretch;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.declsign-one-big-button{
    height: 180px;
    align-self: stretch;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: var(--background-primary);
    border-radius: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 96%;
    max-width: 590px;
    margin-left: 2%;
}

.declsign-one-big-button p{
    color: #707070;
    font-size: 18px;
}

.declsign-one-big-button img{
    margin-top: 20px;
}

.declsign-big-button{
    width: 48%;
    max-width: 295px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: var(--background-primary);
    border-radius: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.declsign-big-button p{
    margin-bottom: 84px;
    color: #707070;
    font-size: 18px;
}
