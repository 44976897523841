@keyframes bottomToUp {
    from{
        margin-top: 100vw;
        height: 300vw;
    }

    to{
        margin-top: 0vw;
        height: 100vw;
    }
}

@keyframes UpToBottom {
    to{
        margin-top: 120vw;
        height: 300vw;
    }

    from{
        margin-top: 0vw;
        height: 100vw;
    }
}

@keyframes fadeIn {
    from{
        background-color: rgba(0, 0, 0, 0);
    }

    to{
        background-color: rgba(0, 0, 0, 0.2);
    }
}

@keyframes fadeOut {
    to{
        background-color: rgba(0, 0, 0, 0);
    }

    from{
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.display-element-main{
    width: 100dvw;
    height: 100dvh;
    position: absolute;
    overflow: hidden auto;
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
    animation-duration: 450ms;
    animation-name: fadeIn;
    opacity: 1;
    scroll-behavior: smooth;
    pointer-events:fill;
}

.display-element-empty-space{
    height: 50vh;
}

.display-element-wrapper{
    width: 100dvw;
    min-height: 50dvh;
    border-radius: 20px 20px 0px 0px;
    background-color: var(--background-primary);
    box-shadow: 0px -3px 6px #00000029;
    animation-duration: 450ms;
    animation-name: bottomToUp;
}

.display-element-container{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
}

.display-element-round-square{
    background-color: #707070;
    opacity: 0.2;
    width: 51px;
    height: 5px;
    align-self: center;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 50px;
}

.display-element-title{
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
    align-self: center;
    text-align: center;
    font-weight: bold;
}

.display-element-txt{
    font-size: 15px;
    margin: 0;
    margin-bottom: 20px;
}

.display-element-input{
    background: var(--background-primary) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border: 0;
    border-radius: 10px;
    height: 43px;
    text-align: center;
    font: normal normal normal 14px/19px Segoe UI;
    align-self: stretch;
}

.display-element-input::placeholder{
    font-style: italic;
}

.display-element-big-input{
    height: 98px;
}

.display-element-button{
    margin-top: 20px;
    height: 53px;
    box-shadow: 0px 0px 6px #85858580;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.display-element-button-valid{
    background-color: #3DBFF1;
}

.display-element-button p{
    margin: 0;
    font-size: 20px;
    text-align: center;
    align-self: center;
    color: white;
}