.woelement-main{
    height: 108px;
    justify-self: stretch;
    margin-top: 15px;
    margin-bottom: 15px;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 70px auto;
}

.woelement-icon-container{
    box-shadow: 0px 3px 6px #00000029;
    background-color: white;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    align-self: center;
    grid-row: 1;
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.woelement-icon{
    width: 50%;
    height: 50%;
}

.woelement-container{
    justify-self: stretch;
    align-self: stretch;
    margin-left: 35px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: white;
    grid-row: 1;
    grid-column: 1 / span 2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 40px;
    padding-right: 15px;
}

.woelement-title{
    font-size: 14px;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow-y: hidden;
}

.woelement-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.woelement-status{
    font-size: 11px;
    font-style: italic;
    margin: 0;
}

.woelement-num-work{
    font-size: 11px;
    font-style: italic;
    margin: 0;
}

.woelement-building{
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}