.wodetail-main{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.wodetail-wrapper{
    display: block;
    overflow: hidden auto;
    height: calc(100dvh - var(--nav-height));
}

.wodetail-container{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
    flex-wrap: nowrap;
}

.wodetail-title{
    margin: 0;
    margin-top: 20px;
    font-weight: normal;
    text-align: left;
    font-size: 40px;
    color: black;
}

.wodetail-subtitle{
    margin: 0;
    margin-top: 12px;
    font-weight: normal;
    text-align: left;
    font-size: 20px;
    color: black;
}

.wodetail-status-container{
    margin-top: 15px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.wodetail-status-img{
    width: 30px;
    height: 30px;
}

.wodetail-status-txt{
    margin: 0;
    font-size: 16px;
    margin-left: 10px;
    font-style: italic;
}

.wodetail-info{
    margin: 0;
    margin-top: 15px;
    font-weight: normal;
    font-size: 16px;
    color: black;
}

.wodetail-separator{
    margin: 0;
    margin-top: 20px;
    height: 1px;
    align-self: stretch;
    border: 0;
    border-radius: 10px;
    background-color: #707070;
    opacity: 0.2;
}

.wodetail-annexe-title{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-top: 20px;
}

.wodetail-security-container{
    margin-top: 20px;
}

.wodetail-button-icon{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 23px;
}

.wodetail-button-icon p{
    margin: 0;
    margin-left: 9px;
    font-size: 20px;
}

.wodetail-button-declare{
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wodetail-button-declare p{
    margin: 0;
    font-size: 20px;
}

.wodetail-bottom{
    margin-top: 20px;
}