.message-main{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: [col1] 10% [col2] auto [col3] 10%;
    grid-template-rows:[row2] 1fr [row2] 3fr [row3] 120px;
    justify-content: stretch;
    pointer-events:fill;
    animation-duration: 0.5s;
    animation-name: slidein;
}

.message-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row: 2;
    grid-column: 2;
}

.message-icon{
    width: 100px;
    height: 100px;
}

.message-text{
    text-align: center;
    color: white;
    font-size: 18px;
}

.message-description{
    text-align: center;
    font-style: italic;
    color: white;
    font-size: 15px;
    margin-bottom: 50px;
}

.message-button{
    height: 70px;
    /* UI Properties */
    border: 3px solid #FFFFFF;
    background-color: transparent;
    border-radius: 20px;
    font-size: 20px;
    color: #FFFFFF;
    opacity: 1;
    grid-row: 3;
    grid-column: 2;
}