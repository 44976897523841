.goback-main{
    height: var(--nav-height);
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    align-self: stretch;
    z-index: 1;
}

.goback-clickable{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 25px;
}

.goback-text{
    margin-left: 10px;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}